const broadcastHelperMixin = {
  methods: {
    discoverShareId() {
      let { shareId } = this.$route.params;
      shareId = shareId.toUpperCase();
      shareId = shareId.replace(/-/g, ''); // strip -
      shareId = shareId.replace(/[IL]/g, '1'); // convert i I l L
      shareId = shareId.replace(/O/g, '0'); // convert O o
      this.shareId = shareId;
      return shareId;
    },
  },
};
export default broadcastHelperMixin;
