const { marked } = require('marked');

const renderer = new marked.Renderer();
const linkRenderer = renderer.link;
renderer.link = (href, title, text) => {
  const html = linkRenderer.call(renderer, href, title, text);
  return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
};

const messageMixin = {
  data() {
    return {
      message: null,
      startMessage: null,
    };
  },
  watch: {
    'broadcast.eventPassthrough': function eventPassthrough(newValue, oldValue) {
      const newMessage = newValue?.message || '';
      const oldMessage = oldValue?.message || '';
      if (newMessage !== oldMessage) {
        this.message = marked(newMessage, { renderer });
      }
      const newStartMessage = newValue?.startMessage || '';
      const oldStartMessage = oldValue?.startMessage || '';
      if (newStartMessage !== oldStartMessage) {
        this.startMessage = marked(newStartMessage, { renderer });
      }
    },
  },
};
export default messageMixin;
